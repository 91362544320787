
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { TimeUtil } from '@/helpers/TimeUtil'
import { commonModule } from '@/store/modules/store-accessor'
import ApexChart from "vue-apexcharts";
import DeviceSideviewLineChart from '@/components/Charts/DeviceSideviewLineChart.vue';
import DeviceSideviewBarChart from '@/components/Charts/DeviceSideviewBarChart.vue';
import config from "@/config";
import i18n from "@/i18n";
import dayjs from 'dayjs';
import _ from 'lodash';

@Component({
  components: {
    ApexChart,
    DeviceSideviewLineChart,
    DeviceSideviewBarChart
  }
})
export default class MBChartView extends Vue {
 @Prop() device: Record<string, any>;

  dateRangeMenuState = false;

  dateRange = [dayjs().startOf('week').format('YYYY-MM-DD'), dayjs().endOf('day').format('YYYY-MM-DD')];
  dateForCharts = { date_from: dayjs().startOf('week').valueOf() * 1000000, date_to: dayjs().endOf('week').valueOf() * 1000000 };

  dateRangeUpdated(date: any) {
    let sorted = date.sort((a: any, b: any) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })
    let dateFrom = dayjs(sorted[0]).startOf('day').valueOf() * 1000000;
    let dateTo = dayjs(sorted[1]).endOf('day').valueOf() * 1000000;
    this.dateForCharts = { date_from: dateFrom, date_to: dateTo }
  }

  updateDateRangeFromCharts(dateObj: any) {
    this.dateRange = [dayjs.unix(dateObj.date_from / 1000000000).format('YYYY-MM-DD HH:mm'), dayjs.unix(dateObj.date_to / 1000000000).format('YYYY-MM-DD HH:mm')];
    this.dateForCharts = _.cloneDeep(dateObj);
  }

  get dateRangeText () {
    // let dateRangeText = this.dateRange.map((item) => dayjs(item).format('YYYY-MM-DD HH:mm'))
    let sorted: any = this.dateRange.sort((a: any, b: any) => {
      var dateA = dayjs(a).unix(),
          dateB = dayjs(b).unix();
      if (dateB > dateA) {
        return -1;
      } else {
        return 1;
      }
    })

    // The V-datepicker, returns only date strings, and we forcefully add startof day end of day to fetch the data for 24-hour days. 
    // This condition checks if the string already contains hours:minutes string. If it does then we do not format it to 
    // to add the HH:mm. 
    if(/:/.test(this.dateRange[0])) {
      return sorted.join(' - ')
    }
    else {
      let formattedDates = [] 
      
      formattedDates.push(dayjs(sorted[0]).startOf('day').format('YYYY-MM-DD HH:mm'))

      if(sorted[1]) {
        formattedDates.push(dayjs(sorted[1]).endOf('day').format('YYYY-MM-DD HH:mm'))
      }

      return formattedDates.join(' - ')
    }
  }

  openExportModal() {
    commonModule.showModal({ name: 'export-device-data-modal', payload: { device: this.device}})
  }
};
